import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-constraint-snack-bar',
  templateUrl: './constraint-snack-bar.component.html',
  styleUrls: ['./constraint-snack-bar.component.scss']
})
export class ConstraintSnackBarComponent implements OnInit {

  public showDetails = false;

  constructor(
    private snackBarRef: MatSnackBarRef<ConstraintSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public msg: string) { }

  ngOnInit(): void { }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }

}
