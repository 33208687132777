import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LogoutButtonComponent } from './logout-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    LogoutButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  exports: [
    LogoutButtonComponent,
  ]
})
export class LogoutButtonModule { }
