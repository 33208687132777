import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordButtonComponent } from './change-password-button.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ChangePasswordButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  exports: [
    ChangePasswordButtonComponent
  ]
})
export class ChangePasswordButtonModule { }
