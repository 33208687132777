import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AppStorage } from 'src/app/utils/app-storage';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

    @AppStorage('userRoles') userRoles: string[];

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole: string = route.data?.expectedRole;
        if (!expectedRole || !this.userRoles.includes(expectedRole)) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
