import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppStorage } from 'src/app/utils/app-storage';
import { MenuOption } from 'src/types';

@Component({
  selector: 'app-side-menu-options',
  templateUrl: './side-menu-options.component.html',
  styleUrls: ['./side-menu-options.component.scss']
})
export class SideMenuOptionsComponent implements OnInit {

  @AppStorage('userRoles') userRoles: string[];
  @AppStorage('menuOptions') menuOptions: MenuOption[];

  @Output() tabAdded = new EventEmitter<MenuOption>();

  constructor() { }

  ngOnInit(): void {
  }

  addTab(option: MenuOption): void {
    this.tabAdded.emit(option);
  }

}
