interface AccessConfig {
    [url: string]: {
        role: string;
        edit: string;
    };
}

export const accessConfig: AccessConfig = {
    operations: {
        role: 'admin',
        edit: '',
    },
    cards: {
        role: 'admin',
        edit: '',
    },
    history: {
        role: 'admin',
        edit: '',
    },
    'client-users': {
        role: 'admin',
        edit: '',
    },
    providers: {
        role: 'admin',
        edit: '',
    },
    reports: {
        role: 'admin',
        edit: '',
    },
};
