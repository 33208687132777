import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './shared/header/header.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FooterModule } from './shared/footer/footer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from 'src/CustomMatPaginatorIntl';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorInterceptor } from './utils/error.interceptors';
import { SideMenuOptionsModule } from './shared/side-menu-options/side-menu-options.module';
import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import { JsonPipe, PlatformLocation, registerLocaleData } from '@angular/common';
import { ErrorDialogModule } from './shared/error-dialog/error-dialog.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RequestInterceptor } from './utils/request.interceptor';
import { AppStorage } from './utils/app-storage';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-reuse-strategy';
import { LoaderModule } from './shared/loader/loader.module';
import { ConstraintSnackBarModule } from './shared/constraint-snack-bar/constraint-snack-bar.module';

registerLocaleData(localePl, 'pl');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${(new Date()).getTime()}`);
}

function initializeKeycloak(keycloak: KeycloakService, platform: PlatformLocation): () => Promise<any> {
  const login = () => {
    const urlArray = window.location.hostname.split('.');
    keycloak.login({ idpHint: urlArray[0] });
  };
  return async () => {
    const base = platform.getBaseHrefFromDOM();
    const config = await fetch(`./app.config.json?v=${(new Date()).getTime()}`);
    const data = await config.json();
    if (Object.keys(data).length !== 0) {
      environment.keycloakConfig = data.keycloakConfig;
      environment.apiUrl = data.apiUrl;
      environment.datatransUrl = data.datatransUrl;
    }
    return keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + `/${base}assets/silent-check-sso.html`,
      },
      bearerExcludedUrls: ['./assets'],
    }).then((success) => {
      if (!success) {
        login();
      }
    }).catch(() => {
      login();
    });
  };
}

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HeaderModule,
    MatSidenavModule,
    FooterModule,
    FlexLayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    KeycloakAngularModule,
    MatIconModule,
    MatTabsModule,
    SideMenuOptionsModule,
    ErrorDialogModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatNativeDateModule,
    LoaderModule,
    ConstraintSnackBarModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, PlatformLocation],
    },
    {
      provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { showFirstLastButtons: true },
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en'
    },
    {
      provide: DateAdapter,
      useClass: MyDateAdapter
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    JsonPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  @AppStorage('version') version = '0.0.16';
}
