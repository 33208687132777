import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppStorage } from 'src/app/utils/app-storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @AppStorage('tenant') tenant: string;

  @Input() fullName: string;
  @Output() menuToggled = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    this.menuToggled.emit();
  }

}
