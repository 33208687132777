import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateParser, TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

    private rangeLabelIntl: string;
    private rangeLabelShortIntl: string;

    constructor(
        private translateService: TranslateService,
        private translateParser: TranslateParser) {
        super();
        this.getTranslations();
        this.translateService.onLangChange.subscribe(() => {
            this.getTranslations();
        });
    }

    getTranslations(): void {
        this.translateService.get([
            'PAGINATOR.ITEMS_PER_PAGE',
            'PAGINATOR.NEXT_PAGE',
            'PAGINATOR.PREVIOUS_PAGE',
            'PAGINATOR.FIRST_PAGE',
            'PAGINATOR.LAST_PAGE',
            'PAGINATOR.RANGE',
            'PAGINATOR.RANGE_SHORT'
        ])
            .subscribe((translation) => {
                this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
                this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
                this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
                this.firstPageLabel = translation['PAGINATOR.FIRST_PAGE'];
                this.lastPageLabel = translation['PAGINATOR.LAST_PAGE'];
                this.rangeLabelIntl = translation['PAGINATOR.RANGE'];
                this.rangeLabelShortIntl = translation['PAGINATOR.RANGE_SHORT'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return this.translateParser.interpolate(this.rangeLabelShortIntl, { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex: startIndex + 1, endIndex, length });
    }

}
