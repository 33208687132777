<mat-accordion (click)="$event.stopPropagation()">
    <mat-expansion-panel #panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>language</mat-icon>
                <span class="mr-2">
                    {{'HEADER.LANGUAGE' | translate}}
                </span>
            </div>
        </mat-expansion-panel-header>
        <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language.id); panel.close()">
            {{language.name}}
        </button>
    </mat-expansion-panel>
</mat-accordion>