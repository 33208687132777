import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeThemeComponent } from './change-theme.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    ChangeThemeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  exports: [
    ChangeThemeComponent,
  ]
})
export class ChangeThemeModule { }
