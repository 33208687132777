import { Component, OnInit } from '@angular/core';
import { AppStorage } from 'src/app/utils/app-storage';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

  @AppStorage('userRoles') userRoles: string[];

  constructor() { }

  ngOnInit(): void {
  }

}
