import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuOptionsComponent } from './side-menu-options.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    SideMenuOptionsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    SideMenuOptionsComponent
  ],
})
export class SideMenuOptionsModule { }
