import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MediaMatcher } from '@angular/cdk/layout';
import { AppStorage } from 'src/app/utils/app-storage';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { MenuOption } from 'src/types';
import { ThemeService } from './services/theme.service';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { DateAdapter } from '@angular/material/core';
import { filter } from 'rxjs/operators';
import { accessConfig } from 'src/access-config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @AppStorage('canEdit') canEdit = false;
  @AppStorage('userName') userName = '';
  @AppStorage('lang') lang = 'en';
  @AppStorage('tenant') tenant = '';
  @AppStorage('userRoles') userRoles: string[] = [];
  @AppStorage('menuOptions') menuOptions: MenuOption[] = [
    {
      link: '',
      icon: 'home',
      label: 'NAVIGATION.HOME',
      role: '',
    },
    {
      link: 'operations',
      icon: 'payments',
      label: 'NAVIGATION.OPERATIONS',
    },
    {
      link: 'cards',
      icon: 'credit_card',
      label: 'NAVIGATION.CARDS',
    },
    {
      link: 'history',
      icon: 'history',
      label: 'NAVIGATION.HISTORY',
    },
    {
      link: 'providers',
      icon: 'store',
      label: 'NAVIGATION.PROVIDERS',
    },
    {
      icon: 'group',
      link: 'client-users',
      label: 'NAVIGATION.CLIENT_USERS',
    },
    {
      icon: 'table_chart',
      link: 'reports',
      label: 'NAVIGATION.REPORTS',
    },
  ];
  @AppStorage('newTab', true) newTab: BehaviorSubject<string>;

  public fullName: string;
  public mobileQuery: MediaQueryList;
  public activeLink = '';
  public tabs: MenuOption[] = [];
  public tokenInterval: Subscription;
  public loading = false;

  private titleBase = 'BonnMobil';

  constructor(
    private translate: TranslateService,
    private keycloakService: KeycloakService,
    private media: MediaMatcher,
    private router: Router,
    private location: Location,
    private themeService: ThemeService,
    private titleService: Title,
    private update: SwUpdate,
    private dateAdapter: DateAdapter<any>) {
    const datatrans = document.createElement('script');
    datatrans.setAttribute('type', 'text/javascript');
    datatrans.setAttribute('src', environment.datatransUrl);
    document.body.appendChild(datatrans);
    this.themeService.restoreTheme();
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    const userDetails = this.keycloakService.getKeycloakInstance()?.tokenParsed as any;
    if (userDetails) {
      const roles: string[] = this.keycloakService.getUserRoles();
      const prefix = 'bmt-';
      for (const role of roles) {
        if (role.slice(0, prefix.length) === prefix) {
          this.userRoles.push(role.slice(prefix.length));
        }
      }
      this.userRoles.sort((a, b) => a.localeCompare(b));
      this.tokenInterval = interval(1000).subscribe(() => {
        this.keycloakService.updateToken(60)
          .then(() => { })
          .catch(() => {
            window.location.reload();
          });
      });
    }
    this.userName = userDetails?.preferred_username;
    this.fullName = userDetails?.name;
    this.setLanguage(userDetails);
    for (const option of this.menuOptions) {
      if (option.subMenu) {
        let optionsCount = 0;
        for (const sub of option.subMenu) {
          sub.icon = option.icon;
          sub.role = accessConfig[sub.link]?.role;
          if (this.userRoles.includes(sub.role)) {
            optionsCount++;
          }
        }
        if (optionsCount === 0) {
          option.showOption = false;
        }
      } else {
        option.role = accessConfig[option.link]?.role ?? '';
      }
    }
    this.titleService.setTitle(this.titleBase);
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.substring(1).split('/');
        const urlBase = url[0];
        const edit = accessConfig[urlBase]?.edit ?? '';
        this.canEdit = (edit === '' || this.userRoles.includes(edit));
        this.setTitle(urlBase);
      }
    });
    this.restoreTabs();
    this.newTab.pipe(filter((s) => !!s)).subscribe((next) => {
      this.router.navigate([next], { skipLocationChange: true });
    });
  }

  ngOnInit(): void {
    this.update.available.subscribe(() => {
      this.update.activateUpdate().then((event) => {
        document.location.reload();
      });
    });
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.tokenInterval?.unsubscribe();
  }

  setLanguage(userDetails: any): void {
    this.translate.addLangs(['en', 'de', 'pl']);
    const defaultLang = 'en';
    this.translate.setDefaultLang(defaultLang);
    let language = userDetails?.locale;
    if (!language) {
      language = localStorage.getItem('language');
    }
    if (!language) {
      language = this.translate.getBrowserLang();
    }
    this.translate.use(language.match(/pl|en|de/) ? language : defaultLang);
    this.translate.onLangChange.subscribe((next: any) => {
      this.lang = next.lang;
      this.dateAdapter.setLocale(this.lang);
    });
  }

  addTab(option: MenuOption): void {
    if (!this.tabs.includes(option)) {
      this.tabs.push(option);
    }
    this.activeLink = option.link;
    this.updateCurrentState();
  }

  removeTab(option: MenuOption): void {
    const index = this.tabs.indexOf(option);
    this.tabs.splice(index, 1);
    if (option.link === this.activeLink) {
      const { link } = this.tabs[index ? (index - 1) : 0];
      this.activeLink = link;
      this.updateUrl(link);
    }
    this.updateCurrentState();
  }

  closeAll(): void {
    this.defaultTab();
    this.updateCurrentState();
  }

  changeTab(option: MenuOption): void {
    this.redirect(option);
    this.updateCurrentState();
  }

  private restoreTabs(): void {
    const navigationTabs = sessionStorage.getItem('navigation_tabs');
    if (navigationTabs) {
      try {
        const tabs = JSON.parse(navigationTabs) as string[];
        for (const tab of tabs) {
          const option = this.findMenuOption(tab);
          if (option && this.canAccessOption(option)) {
            this.tabs.push(option);
          }
        }
        if (!this.tabs.length) {
          this.defaultTab();
        }
      } catch (err) {
        this.defaultTab();
      }
    } else {
      this.defaultTab();
    }
    const navigationActive = sessionStorage.getItem('navigation_active');
    if (navigationActive) {
      let option = this.findMenuOption(navigationActive);
      if (!option || !this.canAccessOption(option)) {
        option = this.tabs[0];
      }
      this.redirect(option);
    } else {
      this.redirect(this.tabs[0]);
    }
    this.updateCurrentState();
  }

  private findMenuOption(link: string): MenuOption {
    let option = this.menuOptions.find((o) => o.link === link);
    if (!option) {
      const withSub = this.menuOptions.filter((o) => !!o.subMenu);
      const findSub = withSub.find((o) => o.subMenu.find((s) => s.link === link));
      if (findSub) {
        option = findSub.subMenu.find((s) => s.link === link);
      }
    }
    return option;
  }


  private redirect(option: MenuOption): void {
    this.activeLink = option.link;
    this.updateUrl(option.link);
  }

  private updateUrl(link: string): void {
    this.location.replaceState('/');
    this.router.navigate(['/', link], { skipLocationChange: true });
  }

  private defaultTab(): void {
    this.tabs = [this.menuOptions[0]];
    this.redirect(this.tabs[0]);
  }

  private updateCurrentState(): void {
    sessionStorage.setItem('navigation_tabs', JSON.stringify(this.tabs.map((tab) => tab.link)));
    sessionStorage.setItem('navigation_active', this.activeLink);
  }

  private canAccessOption(option: MenuOption): boolean {
    return option.role === '' || this.userRoles.includes(option.role);
  }

  private async setTitle(url: string): Promise<void> {
    const option = this.findMenuOption(url);
    const label = await this.translate.get(option.label).toPromise();
    this.titleService.setTitle(`${this.titleBase} | ${label}`);
  }

}
