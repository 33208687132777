<div class="nav-container">
    <app-header [fullName]="fullName" (menuToggled)="snav.toggle()"></app-header>
    <mat-sidenav-container fxFlex="row">
        <mat-sidenav class="nav-sidenav" #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" fixedBottomGap="35" [opened]="!mobileQuery.matches">
            <app-side-menu-options (tabAdded)="addTab($event)"></app-side-menu-options>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="h-100">
                <nav mat-tab-nav-bar>
                    <a
                        class="p-0"
                        mat-tab-link
                        *ngFor="let tab of tabs"
                        (click)="changeTab(tab)"
                        [active]="activeLink == tab.link">
                        <div class="tab-option" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                            <span fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                <mat-icon class="mr-2">{{tab.icon}}</mat-icon>
                                {{tab.label | translate}}
                            </span>
                            <mat-icon *ngIf="tabs.length > 1" (click)="removeTab(tab)">clear</mat-icon>
                        </div>
                    </a>
                    <a
                        class="p-0"
                        mat-tab-link
                        *ngIf="tabs.length > 1"
                        (click)="closeAll()">
                        <div class="tab-option" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                            <mat-icon class="mr-2">backspace</mat-icon>
                            {{'NAVIGATION.CLOSE_ALL' | translate}}
                        </div>
                    </a>
                </nav>
                <div class="router-container">
                    <app-loader *ngIf="loading"></app-loader>
                    <ng-container *ngIf="!loading">
                        <router-outlet></router-outlet>
                    </ng-container>
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <app-footer></app-footer>
</div>