<mat-nav-list>
    <ng-container *ngFor="let option of menuOptions">
        <ng-container *ngIf="!option.hidden && (option.role === '' || userRoles.includes(option.role) || option.showOption)">
            <ng-container *ngIf="!option.subMenu">
                <a mat-list-item [routerLink]="['/', option.link]" skipLocationChange (click)="addTab(option)">
                    <mat-icon>{{option.icon}}</mat-icon>
                    {{option.label | translate }}
                </a>
            </ng-container>
            <ng-container *ngIf="option.subMenu">
                <a mat-list-item [class.activated]="option.showSub" (click)="option.showSub = !option.showSub">
                    <mat-icon>{{option.icon}}</mat-icon>
                    {{option.label | translate }}
                    <mat-icon *ngIf="!option.showSub">chevron_right</mat-icon>
                    <mat-icon *ngIf="option.showSub">expand_more</mat-icon>
                </a>
                <mat-nav-list *ngIf="option.showSub">
                    <ng-container *ngFor="let sub of option.subMenu">
                        <ng-container *ngIf="sub.role === '' || userRoles.includes(sub.role)">
                            <mat-divider></mat-divider>
                            <a mat-list-item [routerLink]="['/', sub.link]" skipLocationChange (click)="addTab(sub)">
                                {{sub.label | translate }}
                            </a>
                        </ng-container>
                    </ng-container>
                </mat-nav-list>
            </ng-container>
            <mat-divider></mat-divider>
        </ng-container>
    </ng-container>
</mat-nav-list>