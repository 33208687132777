import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from './guards/role.guards';
import { accessConfig } from 'src/access-config';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    data: {
      key: 'home',
    }
  },
  {
    path: 'operations',
    canActivate: [RoleGuard],
    loadChildren: () => import('./pages/operations/operations.module').then(m => m.OperationsModule),
    data: {
      expectedRole: accessConfig.operations.role,
      key: 'operations',
    }
  },
  {
    path: 'cards',
    canActivate: [RoleGuard],
    loadChildren: () => import('./pages/cards/cards.module').then(m => m.CardsModule),
    data: {
      expectedRole: accessConfig.cards.role,
      key: 'cards',
    }
  },
  {
    path: 'history',
    canActivate: [RoleGuard],
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryModule),
    data: {
      expectedRole: accessConfig.history.role,
      key: 'history',
    }
  },
  {
    path: 'client-users',
    canActivate: [RoleGuard],
    loadChildren: () => import('./pages/client-users/client-users.module').then(m => m.ClientUsersModule),
    data: {
      expectedRole: accessConfig['client-users'].role,
      key: 'client-users',
    }
  },
  {
    path: 'providers',
    canActivate: [RoleGuard],
    loadChildren: () => import('./pages/providers/providers.module').then(m => m.ProvidersModule),
    data: {
      expectedRole: accessConfig.providers.role,
      key: 'providers',
    }
  },
  {
    path: 'reports',
    canActivate: [RoleGuard],
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    data: {
      expectedRole: accessConfig.reports.role,
      key: 'reports',
    }
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
