import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ConstraintSnackBarComponent } from '../shared/constraint-snack-bar/constraint-snack-bar.component';
import { JsonPipe } from '@angular/common';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private close = '';
  private unauthorized = '';
  private server = '';
  private forbidden = '';

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private jsonPipe: JsonPipe) {
    this.getTranslation();
    this.translateService.onLangChange.subscribe(() => {
      this.getTranslation();
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
        const errorMsg: string = err.error?.error?.message || err.statusText;
        const transactionError = [
            'v1/transactioncheck',
            'v1/authorise',
            'v1/settle',
            'v1/refund',
            'v1/userverify/token',
          ].some((url) => err.url.includes(url));
        if (errorMsg.includes('constraint')) {
          this.snackbar.openFromComponent(ConstraintSnackBarComponent, {
            data: errorMsg,
            duration: 15000,
          });
        } else if (errorMsg.toLowerCase() === 'unauthorized') {
          this.snackbar.open(this.unauthorized, this.close, {
            duration: 15000,
          });
        } else if (err.status === 403) {
          this.snackbar.open(this.forbidden, this.close, {
            duration: 15000,
          });
        } else {
          if (transactionError) {
            this.snackbar.open(this.jsonPipe.transform(err.error), this.close, {
              duration: 15000,
            });
          } else {
            this.snackbar.open(this.server, this.close, {
              duration: 15000,
            });
          }
        }
        const error = err.error.message || err.statusText;
        if (transactionError) {
          return throwError(err.error);
        }
        return throwError(error);
      }));
  }

  private getTranslation(): void {
    this.translateService.get([
      'MISC.CLOSE',
      'ERRORS.UNAUTHORIZED',
      'ERRORS.SERVER',
      'ERRORS.FORBIDDEN',
    ]).subscribe((translation) => {
      this.close = translation['MISC.CLOSE'];
      this.unauthorized = translation['ERRORS.UNAUTHORIZED'];
      this.server = translation['ERRORS.SERVER'];
      this.forbidden = translation['ERRORS.FORBIDDEN'];
    });
  }
}
