import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private current: string;
  private body = document.body;

  constructor() { }

  restoreTheme(): void {
    const theme = localStorage.getItem('theme');
    this.current = theme === 'dark-theme' ? 'dark-theme' : 'light-theme';
    this.body.classList.add(this.current);
  }

  changeTheme(): void {
    const theme = this.current === 'dark-theme' ? 'light-theme' : 'dark-theme';
    localStorage.setItem('theme', theme);
    this.body.classList.remove(this.current);
    this.body.classList.add(theme);
    this.current = theme;
  }

}
