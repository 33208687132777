import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-password-button',
  templateUrl: './change-password-button.component.html',
  styleUrls: ['./change-password-button.component.scss']
})
export class ChangePasswordButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  changePassword(): void {
    const { keycloakConfig } = environment;
    window.location.href = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/account/password`;
  }

}
