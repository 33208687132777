<mat-dialog-content>
    <div fxFill fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>
            highlight_off
        </mat-icon>
        <h1>{{msg}}</h1>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div fxFill fxLayout="column" fxLayoutAlign="center center">
        <button mat-raised-button (click)="close()">
            {{'MISC.CLOSE' | translate}}
        </button>
    </div>
</mat-dialog-actions>