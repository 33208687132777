import { BehaviorSubject } from 'rxjs';

interface IStorage {
    [param: string]: BehaviorSubject<any>;
}
const storage: IStorage = {};
export const AppStorage = (param: string, watch = false) => {
    return (target: any, key: string) => {
        const val = target[key];
        if (!storage.hasOwnProperty(param)) {
            storage[param] = new BehaviorSubject(val);
        }
        const getter = () => {
            const current = storage[param];
            return watch ? current : current.value;
        };
        const setter = (next: any) => {
            const current = storage[param];
            current.next(next);
        };
        Object.defineProperty(target, key, {
            get: getter,
            set: setter,
        });
    };
};
