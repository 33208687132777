import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';


export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return true;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        this.storedRoutes.set(route.routeConfig?.data?.key, handle);
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        this.clearStoredRoutes();
        return !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig?.data?.key);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRoutes.get(route.routeConfig?.data?.key);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    private clearStoredRoutes(): void {
        const navigationTabs = sessionStorage.getItem('navigation_tabs');
        if (navigationTabs) {
            try {
                const tabs = JSON.parse(navigationTabs) as string[];
                const keys = this.storedRoutes.keys();
                for (const key of keys) {
                    if (!tabs.includes(key)) {
                        this.storedRoutes.delete(key);
                    }
                }
            } catch (err) {
                this.storedRoutes.clear();
            }
        }
    }
}
