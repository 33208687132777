import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  public languages = [];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.languages = this.translate.getLangs().map((lang) => {
      let name: string;
      switch (lang) {
        case 'en':
          name = 'English';
          break;
        case 'de':
          name = 'Deutsch';
          break;
        case 'pl':
          name = 'Polski';
          break;
      }
      return {
        name,
        id: lang,
      };
    });
  }

  setLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

}
