import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';
import { LogoutButtonModule } from '../logout-button/logout-button.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { ChangeThemeModule } from '../change-theme/change-theme.module';
import { ChangePasswordButtonModule } from '../change-password-button/change-password-button.module';
import { RoleListModule } from '../role-list/role-list.module';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    FlexLayoutModule,
    LanguageSelectorModule,
    LogoutButtonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    ChangeThemeModule,
    ChangePasswordButtonModule,
    RoleListModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
