<mat-toolbar color="primary">
    <div fxLayout="row" fxFlex="100" fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button (click)="toggleMenu()">
                <mat-icon>menu</mat-icon>
            </button>
            <div class="app-name">
                BonnMobil
            </div>
        </div>
        <div class="overflow-hidden d-flex">
            <button mat-button [matMenuTriggerFor]="menu" class="w-100">
                <div class="full-name">
                    <span>
                        {{fullName}}<br>{{tenant}}
                    </span>
                    <mat-icon>more_vert</mat-icon>
                </div>
            </button>
            <mat-menu #menu="matMenu">
                <app-language-selector></app-language-selector>
                <mat-divider></mat-divider>
                <app-change-theme></app-change-theme>
                <mat-divider></mat-divider>
                <app-role-list></app-role-list>
                <mat-divider></mat-divider>
                <app-change-password-button></app-change-password-button>
                <mat-divider></mat-divider>
                <app-logout-button></app-logout-button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>