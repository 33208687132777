import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConstraintSnackBarComponent } from './constraint-snack-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    ConstraintSnackBarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatTooltipModule,
  ],
  exports: [
    ConstraintSnackBarComponent
  ]
})
export class ConstraintSnackBarModule { }
