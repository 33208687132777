<div fxLayoutAlign="space-between center">
    <div>
        <div fxLayoutAlign="start center">
            <span>{{ 'ERRORS.CONSTRAINT' | translate }}</span>
            <button mat-icon-button (click)="showDetails = !showDetails" [matTooltip]="'MISC.DETAILS' | translate">
                <mat-icon>
                    {{ showDetails ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </button>
        </div>
        <div class="my-2" *ngIf="showDetails">
            {{ msg }}
        </div>
    </div>
	<div class="mat-snackbar-action">
		<button mat-button (click)="dismiss()">{{ 'MISC.CLOSE' | translate }}</button>
	</div>
</div>
