<mat-accordion (click)="$event.stopPropagation()">
    <mat-expansion-panel #panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>assignment_ind</mat-icon>
                <span class="mr-2">
                    {{'HEADER.ROLES' | translate}}
                </span>
            </div>
        </mat-expansion-panel-header>
        <div class="my-2" *ngFor="let role of userRoles">
            {{role}}
        </div>
    </mat-expansion-panel>
</mat-accordion>