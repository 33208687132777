import { Component, OnInit } from '@angular/core';
import { AppStorage } from 'src/app/utils/app-storage';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @AppStorage('version') version: string;

  public text = 'Scheidt & Bachmann';

  constructor() { }

  ngOnInit(): void {
  }

}
